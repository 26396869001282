import cssVars from 'css-vars-ponyfill';

cssVars({
	variables: {
		'primary': '22, 16, 86',
		'primary-dark': '57, 49, 133',
		'accent': '241, 67, 67',
		'accent-plus': '255, 255, 255',
	},
});
